// coupangapi.js

// 백엔드 서버에 요청을 보낼 주소를 설정합니다.
// 백엔드 서버가 로컬에서 5000 포트에서 실행 중이라고 가정합니다.
const BACKEND_API_URL = 'https://crawlmall.shop:5001/coupang-deeplink';

/**
 * 백엔드 서버를 통해 Coupang의 shorten URL을 가져옵니다.
 * @param {string} link - Coupang의 제품 링크 일부분입니다.
 * @returns {Promise<string>} - Coupang의 shorten URL을 반환합니다.
 */
export const getShortenUrl = async (link) => {
    try {
        // 백엔드 서버에 POST 요청을 보냅니다.
        const response = await fetch(BACKEND_API_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                link: link,
            }),
        });

        // 응답을 JSON 형태로 파싱합니다.
        const data = await response.json();

        // 여기서 응답값을 출력합니다.
        console.log("Response data:", data);

        // 첫번째 shortenUrl을 반환합니다. (이 부분이 수정된 부분입니다.)
        if (data && data.data && data.data.length > 0 && data.data[0].shortenUrl) {
            return data.data[0].shortenUrl;
        } else {
            throw new Error('No shorten URL received from the backend server.');
        }
    } catch (error) {
        console.error("Error fetching the shorten URL:", error);
        throw error;
    }
};
