export const fetchProductData = async (productValue,isNew) => {
    let url;
    
    if (isNew === 'new') {
      url = `https://crawlmall.shop:8080/discount/new/${productValue}`;
    } else if (isNew === 'return') {
      url = `https://crawlmall.shop:8080/discount/return/${productValue}`;
    } else if (isNew === 'used') {
      url = `https://crawlmall.shop:8080/discount/used/${productValue}`;
    } else if (isNew === 'campaign') {
      url = `https://crawlmall.shop:8080/discount/campaign/${productValue}`;
    }

  
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
  
    // "updateTime"의 값이 null인지 확인합니다.
  if (data.updateTime === null) {
    data.updateTime = new Date().toISOString();
  }
      // "updateTime"과 "data" 키가 있는지 확인합니다.
      if (data && data.updateTime && Array.isArray(data.data)) {
        return data;  // 전체 데이터를 반환하도록 변경
      } else {
        throw new Error('Invalid data format');
      }
    } catch (error) {
      console.error('Fetch failed:', error);
      throw error;
    }
  };

  
  
